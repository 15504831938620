<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Menu - Zápatí - Kategorie - seznam</strong>
      </div>
      <action-tools :router-options="routerOptions" :handle-refresh="handleRefresh"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <a-dropdown :placement="'bottomLeft'">
            <a-button>Hromadné akce
              <a-icon type="down"/>
            </a-button>
            <a-menu slot="overlay">
              <a-menu-item :disabled="selectedRowKeys.length === 0" :class="{'disabled': selectedRowKeys.length === 0}">
                <a-popconfirm @confirm="confirmDelete(selectedRowKeys)" okText="Potvrdit" cancelText="Zrušit">
                  <template slot="title">
                    <p>Opravdu si přejete odstranit tyto položky?</p>
                    <div v-if="selectedRowKeys.length > 0">
                      {{ selectedRowKeys }}
                    </div>
                  </template>
                  Odstranit
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div class="text-right col-md-6">
          <a-input-search
            placeholder="Hledat..."
            style="width: 200px"
            @search="handleSearch"
          />
        </div>
      </div>
      <a-table
        tableLayout="auto"
        :rowSelection="rowSelection"
        :rowKey="record => record.mfe_id"
        :columns="columns"
        :loading="loading"
        :dataSource="menuFooterTitle"
        class="utils__scrollTable"
        :scroll="{ x: '100%' }"
      >
        <router-link
          slot="mfe_id"
          slot-scope="value"
          class="utils__link--underlined"
          :to="'/menu/footer/category/detail/'+value"
        >#<span v-if="searchText">
            <template
              v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value}}</template>
        </router-link>
        <template slot="customRender" slot-scope="text">
          <a-alert v-if="text === undefined" message="není nastaveno" type="warning" showIcon />
          <span v-else-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </template>
        <span
          slot="active"
          slot-scope="value"
          :class="[value === undefined ? '' : value ? 'font-size-12 badge badge-success' : 'font-size-12 badge badge-danger']"
        >
          <a-alert v-if="value === undefined" message="není nastaveno" type="warning" showIcon />
          <span v-else-if="searchText">
            <template
              v-for="(fragment, i) in (value ? 'ano' : 'ne').toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value ? 'ano' : 'ne'}}</template>
        </span>
        <span slot="action" slot-scope="record">
          <router-link :to="'/menu/footer/category/detail/'+record.mfe_id">
            <a-button icon="search" size="small" class="mr-1">Zobrazit</a-button>
          </router-link>
          <a-popconfirm @confirm="confirmDelete([record.mfe_id])" okText="Potvrdit" cancelText="Zrušit">
            <template slot="title">
                <p>Opravdu si přejete odstranit položku: #{{ record.mfe_id }} ?</p>
            </template>
            <a-button icon="delete" size="small">Odstranit</a-button>
          </a-popconfirm>
        </span>
        <template slot="footer">
          {{ this.menuFooterTitle.length }} položek
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'

export default {
  components: { ActionTools, LanguageTab },
  data: function () {
    return {
      searchText: '',
      loading: false,
      selectedRowKeys: [],
      routerOptions: [
        {
          icon: 'plus-circle',
          to: '/menu/footer/category/add',
          title: 'Přidat',
        },
        {
          icon: 'sort-ascending',
          theme: 'outlined',
          to: '/menu/footer/category/sort',
          title: 'Seřadit',
        },
      ],
      columns: [
        {
          title: 'ID',
          dataIndex: 'mfe_id',
          scopedSlots: {
            customRender: 'mfe_id',
          },
          sorter: (a, b) => a.mfe_id - b.mfe_id,
        },
        {
          title: 'Nadpis',
          dataIndex: 'languages[0].pivot.mfe_title',
          scopedSlots: {
            customRender: 'customRender',
          },
          sorter: (a, b) => a.languages[0].pivot.mfe_title.localeCompare(b.languages[0].pivot.mfe_title),
        },
        {
          title: 'Pozice',
          dataIndex: 'mfe_position',
          sorter: (a, b) => a.mfe_position - b.mfe_position,
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'E-shop',
          dataIndex: 'mfe_is_ecommerce_categories',
          scopedSlots: {
            customRender: 'active',
          },
          sorter: (a, b) => a.mfe_is_ecommerce_categories - b.mfe_is_ecommerce_categories,
        },
        {
          title: 'Blog',
          dataIndex: 'mfe_is_blog',
          scopedSlots: {
            customRender: 'active',
          },
          sorter: (a, b) => a.mfe_is_blog - b.mfe_is_blog,
        },
        {
          title: 'Akce',
          key: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
    }
  },
  computed: {
    menuFooterTitle: function () {
      return this.$store.getters['menuFooterTitle/currentLanguage'](this.searchText)
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [{
          key: 'all-data',
          text: 'Vybrat všechny položky',
          onSelect: () => {
            this.selectedRowKeys = this.selectedRowKeys.length === 0 ? this.menuFooterTitle.map(item => item.mfe_id) : []
          },
        }],
        onSelection: this.onSelection,
      }
    },
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleRefresh() {
      this.loading = true
      this.$store.dispatch('menuFooterTitle/getList')
        .finally(() => {
          this.loading = false
        }).catch(() => {})
    },
    confirmDelete(menuFooterTitle) {
      this.loading = true
      this.$store.dispatch('menuFooterTitle/delete', menuFooterTitle)
        .then(() => {
          if (this.selectedRowKeys.length > 0) {
            this.selectedRowKeys = menuFooterTitle.filter(x => !this.selectedRowKeys.includes(x))
              .concat(this.selectedRowKeys.filter(x => !menuFooterTitle.includes(x)))
          }
        }).catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    handleSearch(searchText) {
      this.searchText = searchText
    },
  },
  created() {
    if (this.menuFooterTitle.length === 0 && this.$store.getters['language/active'] !== null) {
      this.handleRefresh()
    }
    this.$store.watch(
      (state, getters) => getters['language/active'], () => {
        if (this.$store.getters['language/active'] !== null && this.menuFooterTitle.length === 0) {
          this.handleRefresh()
        }
      },
    )
  },
}
</script>

<style lang="scss" module scoped>

</style>
